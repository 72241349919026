import { adjustStockService, getLogProductList } from '@/api/products/product.service'
import { AdjustStockPayload, Datum, LOG_PRODUCT_TYPE, LogResponse } from '@/api/products/type'
import { reactive, ref } from 'vue'

export enum ADJUST_STOCK_TYPE {
    INCREASE = 'INCREASE',
    DECREASE = 'DECREASE',
  }
  

export const useAdjustStock = () => {
	//
	const adjustTypeList = ref([
		{
			label: 'นำสินค้าเข้า',
			value: ADJUST_STOCK_TYPE.INCREASE
		},
		{
			label: 'นำสินค้าออก',
			value: ADJUST_STOCK_TYPE.DECREASE
		},
	])

	const selectedAdjustType = ref(ADJUST_STOCK_TYPE.INCREASE)
	const quantity = ref(0)
	const remark = ref('')
	const isFetchingList = ref(false)
	const logList = ref<Datum[]>([])
	const pagination = reactive({
		offset: 1,
		limit: 10,
		totals: 0
	})

	const logTypeMapper = (type: LOG_PRODUCT_TYPE): { label: string, type: string } => {
		if (type === LOG_PRODUCT_TYPE.CREATE_ORDER) {
			return {
				label: 'สร้างคำสั่งซื้อ',
				type: 'success'
			}
		}
		if (type === LOG_PRODUCT_TYPE.INIT_SYSTEM) {
			return {
				label: 'สินค้าตั้งต้น',
				type: 'success'
			}
		}
		if (type === LOG_PRODUCT_TYPE.CREATE_PRODUCT) {
			return {
				label: 'สร้างสินค้า',
				type: 'success'
			}
		}
		if (type === LOG_PRODUCT_TYPE.DELETE_ORDER) {
			return {
				label: 'ยกเลิกคำสั่งซื้อ',
				type: ''
			}
		}
		if (type === LOG_PRODUCT_TYPE.EDIT_ORDER) {
			return {
				label: 'แก้ไขคำสั่งซื้อ',
				type: ''
			}
		}
		if (type === LOG_PRODUCT_TYPE.EDIT_UNIT_PRODUCT) {
			return {
				label: 'ปรับจำนวนสินค้า',
				type: ''
			}
		}
		if (type === LOG_PRODUCT_TYPE.STOCK_END_LIVE) {
			return {
				label: 'จบการถ่ายทอดสด',
				type: ''
			}
		}
		if (type === LOG_PRODUCT_TYPE.STOCK_START_LIVE) {
			return {
				label: 'เริ่มการถ่ายทอดสด',
				type: 'success'
			}
		}
		if (type === LOG_PRODUCT_TYPE.UPDATE_STOCK_LIVE) {
			return {
				label: 'แก้ไขระหว่างการถ่ายทอดสด',
				type: 'warning'
			}
		}
		if (type === LOG_PRODUCT_TYPE.STOCK_START_POST_CF) {
			return {
				label: 'เริ่มการโพสต์',
				type: 'success'
			}
		}
		if (type === LOG_PRODUCT_TYPE.STOCK_END_POST_CF) {
			return {
				label: 'จบการโพสต์',
				type: ''
			}
		}
		if (type === LOG_PRODUCT_TYPE.UPDATE_STOCK_POST_CF) {
			return {
				label: 'แก้ไขระหว่างการโพสต์',
				type: 'warning'
			}
		}
		if (type === LOG_PRODUCT_TYPE.CUSTOMER_CF_FROM_POST_CF) {
			return {
				label: 'คำสั่งซื้อ CF โพสต์',
				type: 'success'
			}
		}
		if (type === LOG_PRODUCT_TYPE.CUSTOMER_CF_FROM_LIVE) {
			return {
				label: 'คำสั่งซื้อ CF live',
				type: 'success'
			}
		}
		if (type === LOG_PRODUCT_TYPE.SYNC_ORDER) {
			return {
				label: 'คำสั่งซื้อ Marketplace',
				type: 'success'
			}
		}
		if (type === LOG_PRODUCT_TYPE.SYNC_PRODUCT) {
			return {
				label: 'นำเข้าสินค้า Marketplace',
				type: 'success'
			}
		}
		return {
			label: '-',
			type: ''
		}
	}

	const confirmAdjustStock = async (payload: AdjustStockPayload) => {
		await adjustStockService(payload)
	}

	const fetchLogProductList = async (productId: string) => {
		isFetchingList.value = true
		try {
			const params = {
				offset: pagination.offset,
				limit: pagination.limit,
				productId
			}
			const response = await getLogProductList(params)
            
			logList.value = response.data
			pagination.totals = response.pagination.totals
			// pagination.totals = response
		} catch (error) {
			//
		} finally {
			isFetchingList.value = false
		}
	}

	return {
		adjustTypeList,
		selectedAdjustType,
		quantity,
		remark,
		logList,
		pagination,
		isFetchingList,
		confirmAdjustStock,
		fetchLogProductList,
		logTypeMapper,
	}
}