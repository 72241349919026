import { LocationQueryValue } from 'vue-router'
import { IVariations, ISubProduct } from '@/type/product'

export interface IImages {
	_id: string
	url: string
}
interface Marketplace {
    _id: string;
    name: string;
    skuId: string;
    marketplaceId: string;
    marketplaceType: string;
    marketplaceProductId: string;
	marketplaceShopName: string,
    specialPrice: number;
}

export interface IProductListRes {
	_id?: string
	name: string
	desc: string
	sku: string
	weight: number
	price: number
	costPrice?: number
	unit: number
	quantity?: number | undefined
	shopId?: string
	images: [string] | string[] | []
	createdAt?: string
	updatedAt?: string
	enable?: boolean
	cfCode?: string
	errorCf?: string
	errorPrice?: string
	productSet: { productId: string; unit: number }[]
	deleted?: boolean
	product?:
		| {
				createdAt?: string
				desc: string
				images: [string] | string[] | []
				name?: string
				price: number
				costPrice?: number
				shopId?: string
				sku: string
				quantity?: number
				unit: 121
				updatedAt?: string
				weight: 123
				_id?: string
		  }
		| ISubProductsRes
	variations?: { _id: string; name: string; list: string[] }[]
	subProducts?: Array<ISubProductsRes>
	marketplaces: Marketplace[]
}

export interface IArgumentProductList {
	shopId: string | undefined | null
	offset: number
	limit: number
	page?: number
	search?: string
	seller?: string
	marketplace?: string
}
export interface IArgumentProductCreation {
	name: string
	desc: string
	sku: string
	weight: number
	price: number
	costPrice?: number
	unit: number
	shopId?: string | undefined | null
	image?: string | string[] | null | undefined
	variations?: Array<IVariations>
	subProducts?: Array<ISubProduct>
}
export interface IArgumentProductSetCreation {
	name: string
	desc: string
	sku: string
	price: number
	shopId?: string | undefined | null
	productSet?: { productId: string; unit: number }[]
}
export interface IProductListPages {
	data: IProductListRes[]
	pagination: {
		pages: number
		totals: number
	}
}

export interface IProductsParamiter {
	id: string
	unit: number
}

export interface IProductID {
	productId: string | LocationQueryValue | LocationQueryValue[]
}

export interface IProductDetail {
	name: string
	desc: string
	sku: string
	weight: number
	price: number
	costPrice?: number
	unit: number
}
export interface IProductSetDetail {
	name: string
	desc: string
	sku: string
	price: number
	productSet: IProductSet[]
}
export interface IProductSet {
	product: Product
	_id: string
	unit: number
}

export interface Product {
	images: string[]
	types: string[]
	productId: string
	price: number
	costPrice: number
	weight: number
	sku: string
	parent: string
	parentInfo: ParentInfo
}

export interface ParentInfo {
	name: string
	desc: string
	sku: string
	images: string[]
	variations: Variation[]
}

export interface Variation {
	name: string
	list: string[]
}

export interface ISubProductsRes {
	createdAt?: string
	deleted?: boolean
	deletedAt?: string | null
	images: string[]
	parent?: string
	price: number
	costPrice?: number
	shopId?: string
	sku: string
	types: string[]
	unit: number
	updatedAt?: string
	variations?: string[]
	weight: number
}

export type ProductFromExcle = {
	name: string
	description?: string
	sku: string
	'weight(g)': number
	price: number
	'cost price': number
	unit: number
}

export type ProductErrorMessages = {
	name: string[]
	description: string[]
	sku: string[]
	weight: string[]
	price: string[]
	costPrice: string[]
	unit: string[]
}

export type DetectObject = {
	name: string
	description?: string
	sku: string
	weight: number
	price: number
	costPrice?: number
	unit: number
	isError?: boolean
	errors?: ProductErrorMessages
}

export type ImportProductDetectPayload = {
	shopId: string
	products: DetectObject[]
}

export type ImportProductResult = {
	products: DetectObject[]
	isError: boolean
}

export type AdjustStockPayload = {
	productId: string;
	type: string;
	unit: number;
	remark: string;
}

export type EditStockAndPricePayload = {
	productId: string;
	price: number;
	unit: number;
}

export type LogParams = {
	offset: number
	limit: number
	productId: string
}

export interface LogResponse {
    data:       Datum[];
    pagination: Pagination;
}

export interface Datum {
    _id:       string;
    productId: string;
    shopId:    string;
    sku:       string;
    type:      string;
    total:     number;
    remark:    string;
    timestamp: Date;
}

export interface Pagination {
    totals: number;
    pages:  number;
}

export enum LOG_PRODUCT_TYPE {
	STOCK_START_LIVE = 'STOCK_START_LIVE',
	STOCK_END_LIVE = 'STOCK_END_LIVE',
	UPDATE_STOCK_LIVE = 'UPDATE_STOCK_LIVE',
	CREATE_PRODUCT = 'CREATE_PRODUCT',
	EDIT_UNIT_PRODUCT = 'EDIT_UNIT_PRODUCT',
	EDIT_ORDER = 'EDIT_ORDER',
	CREATE_ORDER = 'CREATE_ORDER',
	DELETE_ORDER = 'DELETE_ORDER',
	INIT_SYSTEM = 'INIT_SYSTEM',
	STOCK_START_POST_CF = 'STOCK_START_POST_CF',
	STOCK_END_POST_CF = 'STOCK_END_POST_CF',
	UPDATE_STOCK_POST_CF = 'UPDATE_STOCK_POST_CF',
	CUSTOMER_CF_FROM_POST_CF = 'CUSTOMER_CF_FROM_POST_CF',
	CUSTOMER_CF_FROM_LIVE = 'CUSTOMER_CF_FROM_LIVE',
	SYNC_ORDER = 'SYNC_ORDER',
	SYNC_PRODUCT = 'SYNC_PRODUCT',
  }
  
